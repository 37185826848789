import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"

import LayoutPage from "../components/layout-page"
import { ValidateEmail } from "../utils/email"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

class IndexPage extends React.Component {
  state = { signupDisabled: true, email: "", name: "", gearDesc: "" }

  render() {
    const { signupDisabled, email, name, gearDesc } = this.state
    const emailInvalid = !ValidateEmail.test(email.trim())
    const nameInvalid = name.trim().length === 0
    const gearDescInvalid = gearDesc.trim().length === 0

    return (
      <LayoutPage>
        <SEO title={"Submit gear for mix:analog"} meta={ImageMeta}/>
        <section className="content_1 bg-light pt-80">
          <div className="container px-xl-0">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-10 text-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                <div className="f-14 color-heading semibold text-uppercase sp-20">Your gear in the cloud</div>
                <h2 className="mt-25">Submit Gear for mix:analog Rental</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="content_11 bg-light pt-100 pb-100">
          <div className="container px-xl-0">
            <div className="row" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
              <div className="col-lg-1" />
              <div className="col-lg-10 color-main op-7 text-justify">
                <p>
                  At mix:analog we are always looking for exciting vintage or modern gear to put online. If you are a gear collector, chances are you have gear that you don't
                  intend to sell, but would not mind if it makes some money for you in the mean time. Especially if this money came without you having to maintain the gear or
                  operate some sort of service like bouncing audio.
                </p>
                <p>
                  If you are a manufacturer, you probably know how expensive it is to get a booth at a trade show or to get your ad seen in a sea of other ads for normal money. And
                  if you are preparing for an overseas market, how well are you really known there and will the distributor promote your brand well?
                </p>
                <p>
                  We're not saying we can help with all your trouble, but mix:analog gives producers and audio engineers paid remote access to a curated collection of maintained
                  gear. If you think your piece or product is missing from what we are offering, don't hesitate to contact us below
                </p>
                <div className="mt-90 mb-25 hr bg-gray h-2 d-none d-md-block" data-aos-duration="600" data-aos="fade-down" />
              </div>
            </div>
            <div className={"row justify-content-center"}>
              <div className={"col-md-4 col-xs-6 text-center"}>
                <a href={"#submit-form"} className={"btn action-1"}>Contact Us</a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light form_2">
          <div className="container px-xl-0">
            <div className="row text-center text-sm-left" id={"submit-form"}>
              <div className="col-lg-5 col-md-6 col-sm-7">
                <form name="Submit Gear List" action="/submit-gear/thank-you" method="POST" className="pt-105 pb-100" data-netlify={"true"}>
                  <input type="hidden" name="form-name" value="Submit Gear List" />
                  <h2 className="mb-45 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    Submit Gear for mix:analog Rental
                  </h2>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Name{" "}
                      <sup>
                        <small>(*)</small>
                      </sup>
                    </div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your name"
                      className="mb-25 w-full input lg border-gray focus-action-1 color-heading placeholder-gray text-center text-sm-left"
                      required
                      value={name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Email{" "}
                      <sup>
                        <small>(*)</small>
                      </sup>
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email"
                      className="mb-25 w-full input lg border-gray focus-action-1 color-heading placeholder-gray text-center text-sm-left"
                      required
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Gear information{" "}
                      <sup>
                        <small>(*)</small>
                      </sup>
                    </div>
                    <textarea
                      name="message"
                      placeholder="What gear"
                      className="mmb-25 w-full input lg border-gray focus-action-1 color-heading placeholder-gray text-center text-sm-left"
                      required
                      style={{ height: "200px" }}
                      value={gearDesc}
                      onChange={e => this.setState({ gearDesc: e.target.value })}
                    />
                  </div>
                  <div className={"pb-20"} />
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className={"mb-10 w-full input lg focus-action-1 color-heading placeholder-gray text-center text-sm-left"}>
                      <ReCAPTCHA onChange={() => this.setState({ signupDisabled: false })} sitekey="6Ld_27AUAAAAADWk3fAMJNr8NfM24uDzBDg7gGBV" />
                    </div>
                  </div>
                  <div className="mt-25 d-flex flex-wrap align-items-center buttons" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <button className="mr-20 mb-20 mb-xl-0 w-210 btn lg action-1" disabled={gearDescInvalid || nameInvalid || emailInvalid || signupDisabled} type={"submit"}>
                      Send
                    </button>
                    <div className="color-heading text-adaptive">
                      By signing up you agree to <br />
                      Our <Link to={"/terms-of-use"}>Terms</Link> and <Link to={"/privacy"}>Privacy Policy</Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-sm-1" />
              <div className="col-lg-6 col-md-5 col-sm-4 d-none d-sm-block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
                <img src={require("../i/form_2_bg.jpg")} className="absolute h-full bg" alt="" />
              </div>
            </div>
          </div>
        </section>
      </LayoutPage>
    )
  }
}

export default IndexPage
